import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Logos({key, index, ...props}) {

  const section = props.logos

  return (
    <section id={section.fieldGroupName + index} className={`my-20 md:my-36`}>
      <div className="container">
        <header className="flex items-center md:mb-12 max-w-2xl mx-auto">
          <TextCard 
            {...section.textCard}
          />
        </header>
        <div className="flex flex-col items-center md:grid md:grid-cols-3 justify-center md:space-y-0 md:space-x-0">
          {section.logos.map((item, i) => (
            <div className='justify-self-center'>
              <FadeInWhenVisible key={`logo${i}`}>
                <Image data={item.logo} className="w-40 h-40" objectFit="contain" objectPosition="center" /> 
              </FadeInWhenVisible>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
